import { configureStore } from '@reduxjs/toolkit';
import jobOpeningReducer from './jobOpeningsSlice.js';
import clientUserReducer from './clientUserSlice.js';
import achievementsReducer from './achievementsSlice.js';
import clientTestimonialReducer from './clientTestimonialSlice.js';
import portfoliosReducer from './protfoliosSlice.js';
import technologyReducer from './technologySlice.js';
import servicesReducer from './servicesSlice.js';
import jobDetailsReducer from './jobDetailsSlice.js';
import contactReducer from './contactSlice.js';

export const store = configureStore({
    reducer: {
        jobDetails: jobDetailsReducer,
        jobOpenings: jobOpeningReducer,
        clientUsers: clientUserReducer,
        achievements: achievementsReducer,
        clientTestimonials: clientTestimonialReducer,
        portfolios: portfoliosReducer,
        technology: technologyReducer,
        serviceData: servicesReducer,
        contact: contactReducer,
    },
});
