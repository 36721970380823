import React from 'react';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import '@reach/dialog/styles.css';
import '../pages/Contacts/contact.css';
import { ImCross } from 'react-icons/im';

function Success_post_dialog() {
  const [showDialog, setShowDialog] = React.useState(true);
 const close = () => setShowDialog(false);

  return (
    <div>
     
      <DialogOverlay isOpen={showDialog} onDismiss={close}>
        <DialogContent
          style={{
            border: 'solid 1px hsla(0, 0%, 0%, 0.5)',
            borderRadius: '10px',
            position: 'relative',
            top: '82px',
            left: '10px',
            maxWidth: '450px',
            paddingTop: '4rem',
             }}
        >
          <p>
            Thank you! Our team will get back to you soon.
            <button
              onClick={close}
              style={{ border: 'none', background: 'none', marginLeft: '2rem' }}
              type="button"
            >
              <ImCross />
            </button>

          </p>
         
        </DialogContent>
      </DialogOverlay>
    </div>
  );
}

export default Success_post_dialog;
