import React from 'react';
import Typewriter from 'typewriter-effect';
import ContactUsButton from '../button/Button';
import '../../Styles/Styles.css';
import { Events, trackEvent } from '../../configs/Analytics';
 
const typewriterTexts = [
  'is a leading React Native and Flutter mobile app development company.',
  'has delivered more than 100 apps.',
  'designs beautiful and user-friendly web and mobile UI/UX.',
  'also provides mobile app consulting services to make your development smooth.',
  ];
  const typewriterTextswiththeme = [
    'is a leading <b style="color : #61DBFB;">React Native</b> and <b style="color : #61DBFB;">Flutter</b> mobile app development company.',
    'has delivered more than<b style="color : #19B962;"> 100 apps.</b>',
    'designs beautiful and user-friendly web and <b style="color : #19B962;">mobile UI/UX.</b>',
    'also provides mobile app <b style="color : #19B962;">consulting services</b> to make your development smooth.',
    ];
  
function HeroSection() {
  return (
    <header className="masthead">
      <div className="container">
        <div className="typewriter">
          <Typewriter
            options={{ loop: true, deleteSpeed: 0.1, delay: 20 }}
            onInit={(typewriter) => {
              typewriterTextswiththeme.forEach((text, index) => {
                const prefix = index === 0 ? 'Brovitech ' : ' ';
                typewriter
                  .typeString(`<b style="font-weight: 700; ">${prefix}</b><span style="opacity: 1;">${text}</span>`)
                  .pauseFor(3000)
                  .deleteChars(typewriterTexts[index].length)
                  .pauseFor(500);

                if (index === typewriterTexts.length - 1) {
                  typewriter.start();
                }
              });
            }}
          />
        </div>
       
        <ContactUsButton onClick={() => {
         trackEvent(Events.contact_us_pressed);
        }}
        >
          <i className="fas fa-paper-plane" style={{ fontSize: '18px' }} />
          <span style={{ fontSize: '18px', marginLeft: '10px' }}>Contact Us</span>
        </ContactUsButton>

      </div>
    </header>
  );
}

export default HeroSection;
