import React from 'react';

function TextareaFieldComponent({ label, name, value, onChange, required, error }) {
  return (
    <div style={{ padding: '10px 20px' }}>
      <label htmlFor={name} className="form_label">{`${label} (${required ? 'required' : 'optional'})`}</label>
      <textarea
        className="form_fields"
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        placeholder={label}
      />
      {error && (
        <div className="error-field">{error}</div>
      )}
    </div>
  );
}

export default TextareaFieldComponent;
