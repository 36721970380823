import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';

function Layout() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflow: 'scroll',
      }}
    >
      <Navbar />
      <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexGrow: 1, width: '100%' }} />
        <Outlet />
        <Footer />
      </div>
    </div>
  );
}

export default Layout;
