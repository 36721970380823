import React from 'react';

function SVLabel(props) {
  const {
    label, para, textClassName, container, ClassName, textColor, textSize,
  } = props;

  return (
    <div className={textClassName}>
      <div className={container}>
        <label
          className={ClassName}
          style={{
    color: textColor,
    fontSize: textSize,
  }}
          htmlFor="inputField"
        >
          {label}
        </label>
        <p>
          {para}
        </p>
      </div>
      <div />
    </div>

  );
}
export default SVLabel;
