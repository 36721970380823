import React, { useEffect } from 'react';
import Slider from 'react-slick';
import { useDispatch, useSelector } from 'react-redux';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../Styles/Styles.css';
import { Avatar } from '@material-ui/core';
import { ShimmerTable, ShimmerSectionHeader } from 'react-shimmer-effects';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { fetchClientTestimonials } from '../../store/clientTestimonialSlice';

function PreviousBtn(props) {
  const { className, onClick } = props;

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === 'Space') {
      onClick();
    }
  };

  return (
    <div
      className={className}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      tabIndex={0} 
      role="button" 
    >
      <ArrowBackIos style={{ color: 'black', fontSize: '45px' }} />
    </div>
  );
}

function NextBtn(props) {
  const { className, onClick } = props;

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === 'Space') {
      onClick();
    }
  };

  return (
    <div
      className={className}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      tabIndex={0} 
      role="button" 
    >
      <ArrowForwardIos style={{ color: 'black', fontSize: '45px' }} />
    </div>
  );
}

const settings = {
  pauseOnHover: false,
  infinite: true,
  speed: 500,
  autoplay: true,
  fade: true,
  variableWidth: false,
  slidesToShow: 1,
  slidesToScroll: 1,
};

function Testimonial() {
  const dispatch = useDispatch();
  const { data, isLoading, isSuccess } = useSelector((state) => state.clientTestimonials);

  useEffect(() => {
    if (data.length === 0) {
      dispatch(fetchClientTestimonials());
    }
  }, []);

  let content = null;

  if (isLoading) {
    content = (
      <>
        <ShimmerSectionHeader center line={1} />
        <ShimmerTable row={0} col={5} />
      </>
    );
  } else if (isSuccess) {
    content = (
      <Slider {...settings} prevArrow={<PreviousBtn />} nextArrow={<NextBtn />} dots>
        {data.clientsTestimonials.map((item) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              textAlign: 'center',
              color: 'gray',
            }}
            key={item.id}
          >
            <Avatar
              imgProps={{ style: { borderRadius: '50%' } }}
              src={item.clientImage}
              style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: 120,
                height: 120,
                border: '1px solid lightgray',
                padding: 7,
                marginBottom: 10,
              }}
            />
            <p style={{ marginTop: 25 }}>
              <div style={{ fontWeight: 500, color: 'black' }}>{item.clientName}</div>
              <div style={{ fontWeight: 500, color: 'grey' }}>{item.clientDesignation}</div>
              <p style={{ fontWeight: 500, color: 'black' }}>{item.description}</p>
            </p>
          </div>
        ))}
      </Slider>
    );
  } else {
    content = <p>Failed to fetch testimonials.</p>;
  }

  return (
    <div className="testimonial" style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}>
      <div style={{ width: '50%', textAlign: 'center' }}>
        <h2 style={{ fontSize: 32, fontWeight: 500, marginBottom: 20 }}>Client testimonials</h2>
        {content}
      </div>
    </div>
  );
}

export default Testimonial;
