import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import { motion } from 'framer-motion';
import { ShimmerTable, ShimmerSectionHeader } from 'react-shimmer-effects';
import { fetchAchievements } from '../../store/achievementsSlice';
import '../../Styles/Styles.css';
import '../card/Cards.css';

export function Achievements() {
  const dispatch = useDispatch();
  const { data, isLoading, isSuccess, error } = useSelector((state) => state.achievements); 

  useEffect(() => {
    if (data.length === 0) {
    dispatch(fetchAchievements());
    }
  }, []); 
  if (isLoading) {
    return (
      <>
        <ShimmerSectionHeader center line={1} />
        <ShimmerTable row={0} col={5} />
      </>
    );
  }

  if (!isSuccess) {
    return (
      <div>
        Error:
        {' '}
        {error}
      </div>
    );
  }

  return (
    <div className="achieve-container">
      <h3 className="achieve">
        {data.achievements.map((item) => (
          <div key={item.id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}> 
              <motion.img
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.8 }}
                style={{ height: 54, width: 54 }}
                src={`images/${item.iconUrl}`}
                alt={item.iconUrl}
              />
              <span className="no_projects">
                {item.count}
              </span>
            </div>
            <p className="clients_name">{item.name}</p>
            <hr className="new1" />
          </div>
        ))}
      </h3>
    </div>
  );
}
