import React, { useState } from 'react';
import { FaCopy, FaCheck } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import CopyToClipboard from 'react-copy-to-clipboard';

import './footerStyles.css';

function Foo() {
  // const email = 'business@brovitech.com';
  // const phone = '+91 9975452800';
  const addressText = `
    Office 809, 8th Floor,
    Global Business Hub,
    Opposite EON IT PARK,
    Kharadi, Pune, Maharashtra 411014, India
  `;

  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);

    // Concatenate email, phone, and address text
    // const copiedText = `Email: ${email}\nPhone: ${phone}\nAddress: ${addressText}`;
    const copiedText = `Address:${addressText}`;
    // Copy the concatenated text to the clipboard
    navigator.clipboard.writeText(copiedText);
  };

  return (
    <div className="footer">
      <div className="sb_footer section_padding">
        <div className="sb_footer-links">
          <div className="sb_footer-links-div">
            <h4>BROVITECH SOLUTIONS LLP</h4>
            <a href="/" style={{ textDecoration: 'none' }}>
              <p>
                We build beautiful web and mobile apps using react framework
              </p>
            </a>
            <a href="/" style={{ textDecoration: 'none' }}>
              <p>@2023 Brovitech. All rights reserved</p>
            </a>
          </div>
          <div className="sb_footer-links-div">
            <h4>Contact Us</h4>
            <a href="mailto:business@brovitech.com" style={{ textDecoration: 'none' }}>
              {/* <p>Email: business@brovitech.com</p> */}
              <p>
                <FontAwesomeIcon style={{ color: 'white', marginRight: '10px' }} icon={faEnvelope} />

                business@brovitech.com

              </p>
            </a>
            <a href="tel:+919975452800" style={{ textDecoration: 'none' }}>

              {/* <p>Sales No. : +91 9975452800</p> */}
              <p>
                <FontAwesomeIcon style={{ color: 'white', marginRight: '10px' }} icon={faPhone} />
                +91 9975452800
              </p>
            </a>
            <CopyToClipboard text={addressText} onCopy={handleCopy}>
              <p
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: '2px',
                }}
                title={copied ? 'Copied!' : 'Copy address'}
              >
                Address:
                {' '}
                {addressText}
                {copied ? (
                  <FaCheck
                    style={{
                      fontSize: '3.5em',
                      marginLeft: '5px',
                      color: 'green',
                      verticalAlign: 'middle',
                      fontWeight: 'bold'
                    }}
                  />
                ) : (
                  <span>
                    <FaCopy
                      style={{
                        fontSize: '1.2em',
                        marginLeft: '5px',
                        verticalAlign: 'middle',
                        fontWeight: 'bold'
                      }}
                    />
                    <span style={{ fontWeight: 'bold', marginLeft: '5px' }}>
                      {copied ? 'Copied!' : ''}
                    </span>
                  </span>
                )}
              </p>
            </CopyToClipboard>
          </div>

          <div className="sb_footer-links-div">
            <h4>Careers</h4>
            <a href="mailto:careers@brovitech.com" style={{ textDecoration: 'none' }}>
              {/* <p>Email: careers@brovitech.com</p> */}
              <p>
                {' '}
                <FontAwesomeIcon style={{ color: 'white', marginRight: '10px' }} icon={faEnvelope} />

                careers@brovitech.com

              </p>
            </a>
            <a href="tel:+919975452800" style={{ textDecoration: 'none' }}>

              {/* <p>Sales No. : +91 8788072163</p> */}
              <p>
                {' '}
                <FontAwesomeIcon style={{ color: 'white', marginRight: '10px' }} icon={faPhone} />
                +91 8788072163

              </p>
            </a>
          </div>

          <div className="sb_footer-links-div">
            <h4>Social Links</h4>
            <a href="https://www.linkedin.com/in/bharat-chavan-brovitech" style={{ textDecoration: 'none' }}>
              <p>{'Founder\'s Linkedin'}</p>
            </a>
            <a href="https://www.linkedin.com/company/brovitech-solutions" style={{ textDecoration: 'none' }}>
              <p>{'Brovitech\'s Linkedin'}</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Foo;
