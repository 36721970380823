import React from 'react';
import './Button.css';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// import { Events, trackEvent } from '../../configs/Analytics';

const STYLES = ['btn--primary', 'btn--outline', 'btn--test'];

const SIZES = ['btn--medium', 'btn--large'];

function ContactUsButton({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize,
  disabled,
}) {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  return (
    <Link to="/contacts" className="btn-mobile">
      <button
        className={`btn ${checkButtonStyle} ${checkButtonSize}`}
        onClick={onClick}
        // eslint-disable-next-line react/button-has-type
        type={type}
        disabled={disabled}
      >
        {children}
      </button>
    </Link>
  );
}

ContactUsButton.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
  buttonSize: PropTypes.string.isRequired,
  buttonStyle: PropTypes.string.isRequired,
  onClick: PropTypes.string.isRequired,

};
export default ContactUsButton;
