/* eslint-disable max-len */
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '../Styles/Styles.css';
// import { Helmet } from 'react-helmet';
import Home from '../pages/home/Home';
import Services from '../pages/portfolio/Portfolio';
import Products from '../pages/portfolio/ClientsProducts';
import Contacts from '../pages/Contacts/Contacts';
import AboutUs from '../pages/About/About';
import JobOpeningsList from '../pages/JobOpenings/JobOpeningsList';
import JobOpeneingsDetails from '../pages/JobOpenings/JobOpeningsDetails';
import JobOpeningForm from '../pages/JobOpenings/JobOpeningForm';
import Layout from './Layout';

function MainRoute() {
  return (
    <Router>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Products />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/jobOpenings" element={<JobOpeningsList />} />
          <Route path="/jobOpeningsform/:jobsDocId" element={<JobOpeningForm />} />
          <Route path="/jobOpeningsDetails/:jobsDocId" element={<JobOpeneingsDetails />} />
        </Route>
      </Routes>

    </Router>
  );
}

export default MainRoute;
