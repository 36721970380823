import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchClientUsers = createAsyncThunk('fetchClientUsers', async () => {
        const response = await axios.get(`${process.env.REACT_APP_URL}/clients-getClients`);
        return response.data;
});
const clientUserSlice = createSlice({
    name: 'clientUsers',
    initialState: {
        isLoading: false,
        data: [],
        isSuccess: false,
        error: null,
    },
    reducers: {
        // add other reducers here if needed.
    },
    extraReducers: (builder) => {
        builder.addCase(fetchClientUsers.pending, (state) => ({
            ...state,
            isLoading: true,
            error: null,
          }));
        builder.addCase(fetchClientUsers.fulfilled, (state, action) => ({
            ...state,
            isLoading: false,
            data: action.payload,
            isSuccess: true,
            error: null,
          }));
        builder.addCase(fetchClientUsers.rejected, (state, action) => ({
            ...state,
            isLoading: false,
            error: action.error.message,
          }));
    }

});

export default clientUserSlice.reducer;
