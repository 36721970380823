import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import BrovitechLogger from '../utils/BrovitechLogger';

export const fetchJobOpeningsById = createAsyncThunk('fetchJobOpeningsById', async (jobsDocId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/jobsOpenings-getJobsPostsById?jobsDocId=${jobsDocId}`);
      BrovitechLogger.log('API Response:', response.data); 
      return response.data;
    } catch (error) {
      BrovitechLogger.error('API Error:', error); 
      throw error; 
    }
  });
  
const jobDetailsSlice = createSlice({
    name: 'jobDetails',
    initialState: {
      isLoading: false,
      data: [],
      isSuccess: false,
      error: null,
    },
    reducers: {
      // add other reducers here if needed.
    },
    extraReducers: (builder) => {
      builder.addCase(fetchJobOpeningsById.pending, (state) => ({
        ...state,
        isLoading: true,
        error: null,
      }));
      builder.addCase(fetchJobOpeningsById.fulfilled, (state, action) => ({
          ...state,
          isLoading: false,
          data: action.payload,
          isSuccess: true,
          error: null,
        }));
      builder.addCase(fetchJobOpeningsById.rejected, (state, action) => ({
        ...state,
        isLoading: false,
        error: action.error.message,
      }));
    },
    
  });
  
export default jobDetailsSlice.reducer;
