import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ShimmerTable, ShimmerSectionHeader, } from 'react-shimmer-effects';
import '../../Styles/Styles.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  FreeMode, Navigation, Pagination, Autoplay,
} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import { Card } from 'react-bootstrap';
import { fetchTechnologies } from '../../store/technologySlice';
import 'bootstrap/dist/css/bootstrap.min.css';

function Products() {
  const dispatch = useDispatch();

  const { data, isLoading, isSuccess } = useSelector((state) => state.technology);

useEffect(() => {
  if (data.length === 0) {
  dispatch(fetchTechnologies());
  }
}, []);

  if (isLoading === true) {
    return (
      <>
        
        <ShimmerSectionHeader center line={1} />
        <ShimmerTable row={0} col={5} />
         
      </>
    );
  }
  return (
    <div className="tech_container py-4 px-4 justify-content">
      <div className="cards_tech">
        <h2>TECHNOLOGIES</h2>
        <h4>What we use</h4>

        <Swiper
          freeMode
          grabCursor
          modules={[FreeMode, Navigation, Pagination, Autoplay]}
          className="mySwiper"
          pagination={{ clickable: true }}
          slidesPerView={1}
          spaceBetween={30}
          navigation={false}
          autoplay={{ delay: 2000 }}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 10,
            },

            480: {
              slidesPerView: 2,
              spaceBetween: 10,
            },

            768: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 15,
            },
          }}
        >
          <Card className="p-0 overflow-hidden h-100 shadow">
            <div className=" overflow-hidden rounded p-0 bg-light">
        
              {isSuccess && (
                <div>
                    {
                      data.technologies?.map((item) => 
                      (
                        <SwiperSlide 
                          key={item.id}
                          className="swiper-slide-img"
                        >
                          <img alt="hdfhf" src={item.imageUrl} key={item.id} />
                        </SwiperSlide>
                      )
                      )

                    }
                </div>
                )}
            </div>
          </Card>
        </Swiper>
      </div>
    </div>
  );
}

export default Products;
