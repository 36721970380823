import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './JobOpenings.css';
import { motion } from 'framer-motion';
import { ShimmerSimpleGallery } from 'react-shimmer-effects';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faCode,
  faBriefcase,
  faUser,
  faMapMarkerAlt,
  faGlobe
} from '@fortawesome/free-solid-svg-icons';
import BrovitechLogger from '../../utils/BrovitechLogger';

import { fetchJobOpeningsList } from '../../store/jobOpeningsSlice';
import { trackScreen } from '../../configs/Analytics';

function JobOpeningsList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, isLoading, isSuccess } = useSelector(
    (state) => state.jobOpenings
  );

  useEffect(() => {
    if (data.length === 0) {
      dispatch(fetchJobOpeningsList());
    }
  }, []);
  useEffect(() => {
    trackScreen('JobOpeningsScreen');
    return () => {};
  }, []);

  if (isLoading === true) {
    return (
      <div className="loader-container">
        <ShimmerSimpleGallery card imageHeight={200} caption row={1} />
      </div>
    );
  }
  const helmetTitle = 'Job Openings';
  const helmetDescription = 'Explore the current job openings at our company.';
  
  if (!isSuccess || !data || data.length === 0) {
    return (
      <>
        <Helmet>
          <title>
            {helmetTitle}
            {' '}
            - BROVITECH : Mobile App Development Company
          </title>
          <meta name="description" content={helmetDescription} />
        </Helmet>
        <div className="no-jobs">No job openings available</div>
      </>
    );
  }

  const onClickViewJob = (item) => {
    // BrovitechLogger.log('item:', item);
    BrovitechLogger.log('item jobsDocId:', item.jobsDocId);

    // navigate(`/jobOpeningsDetails/${item.jobsDocId}`, { state: { item } });
    navigate(`/jobOpeningsDetails/${item.jobsDocId}`);

    // window.location.reload();
  };

  return (
    <>
      <Helmet>
        <title>
          {helmetTitle}
          {' '}
          - BROVITECH : Mobile App Development Company

        </title>
        <meta name="description" content={helmetDescription} />
      </Helmet>
      <div className="job-openings-container">
        <div className="jobListTitle"> We are currently hiring for</div>
        <div className="jobList">
          {isSuccess && (
            <>
              {data.map((item) => (
                <motion.div
                  key={item.id}
                  className="cardStyleJob"
                  whileHover={{ scale: 1.05 }}
                >
                  <h2 className="job-title">{item.jobTitle.value}</h2>
                  <div className="job-location" style={{ textAlign: 'left' }}>
                    <div className="styleList">
                      <FontAwesomeIcon className="icon" icon={faCalendarAlt} />
                      <span className="text">{item.experienceLevel.value}</span>
                    </div>
                    <div className="styleList">
                      <FontAwesomeIcon className="icon" icon={faCode} />
                      <span className="text">{item.skillsRequired.value}</span>
                    </div>
                    <div className="styleList">
                      <FontAwesomeIcon className="icon" icon={faBriefcase} />
                      <span className="text">{item.jobType.value}</span>
                    </div>
                    <div className="styleList">
                      <FontAwesomeIcon className="icon" icon={faGlobe} />
                      <span className="text">{item.environmentType.value}</span>
                    </div>
                    <div className="styleList">
                      <FontAwesomeIcon className="icon" icon={faUser} />
                      <span className="text">{item.openings}</span>
                    </div>
                    <div className="styleList">
                      <FontAwesomeIcon className="icon" icon={faMapMarkerAlt} />
                      <span className="text">{item.location}</span>
                    </div>
                  </div>
                  {/* <button
                        className="view-job-button"
                        type="button"
                        onClick={() => onClickViewJob(item)}
                      >
                        View Details
                      </button> */}
                  <span
                    className="view-job-button"
                    onClick={() => onClickViewJob(item)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === 'Space') {
                        onClickViewJob(item);
                      }
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    View Details
                  </span>
                </motion.div>
              ))}
            </>
          )}
        </div>
      </div>

    </>
  );
}

export default JobOpeningsList;
