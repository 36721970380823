import PropTypes from 'prop-types';
import React from 'react';

function MainPara(props) {
  const { p, textClassName } = props;
  return (
    <div className="masthead-subheading">
      <p className={textClassName}>
        {p}
      </p>

    </div>
  );
}

MainPara.propTypes = {
  p: PropTypes.node.isRequired,
  textClassName: PropTypes.string.isRequired,

};
export default MainPara;
