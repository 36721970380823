import React, { useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faBriefcase,
  faMapMarkerAlt,
  faBuilding
} from '@fortawesome/free-solid-svg-icons';
import './JobOpenings.css';
import { useDispatch, useSelector } from 'react-redux';
import { ShimmerSimpleGallery } from 'react-shimmer-effects';
import { fetchJobOpeningsById } from '../../store/jobDetailsSlice';
import { Events, trackEvent } from '../../configs/Analytics';
import BrovitechLogger from '../../utils/BrovitechLogger';
import FormattedJobDescription from './FormattedJobDescription';

function JobDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const scrollRef = useRef(null);
  const { jobsDocId } = useParams();
  const { data, isLoading, isSuccess } = useSelector((state) => state.jobDetails);
  const jobData = data.jobsPosts;
  BrovitechLogger.log('jobData', jobData);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    if (jobsDocId) {
      dispatch(fetchJobOpeningsById(jobsDocId));
    }
  }, [jobsDocId, dispatch]);

  const onClickView = () => {
    navigate(`/jobOpeningsform/${jobsDocId}`, { state: { jobData } });
    trackEvent(Events.apply_to_job_pressed, { jobId: jobsDocId });
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <ShimmerSimpleGallery card imageHeight={50} caption row={1} />
      </div>
    );
  }

  if (!isSuccess || !jobData) {
    return <div className="no-jobs">No job details available</div>;
  }

  return (
    <div ref={scrollRef} className="jobDetailscontainer">
      
      <Helmet>
        <title>
          {jobData.jobTitle.value}
          {' '}
          - BROVITECH : Mobile App Development Company
        </title>
        <meta name="description" content={`Explore the details of our latest job opening for ${jobData.jobTitle.value} at ${jobData.companyName}. Learn about the required skills, experience level, job type, and more. Apply now to join our dynamic team and contribute to exciting projects in a collaborative work environment.`} />

      </Helmet>
      <div className="jobTitle">
        {jobData.jobTitle.value}
      </div>

      <div className="applyDetailsCardStyle">
        <div className="detailsCard">
          <div style={{ paddingBottom: '2rem', paddingLeft: '1rem' }}>
            <div className="listText">Job Description</div>
            <FormattedJobDescription className="itemListData" jobDescription={jobData.jobDescription} />
          </div>
          <div className="content">
            <div className="list-item">
              <div className="listText">Technical Requirements</div>
              <FormattedJobDescription className="itemListData" jobDescription={jobData.technicalSkills} />

            </div>
          </div>
    
        </div>
        <div className="applyCard">
          <div className="listApplyitem">
            <span><FontAwesomeIcon className="itemIcon" icon={faMapMarkerAlt} /></span>
            <div className="item-info">
              <span className="label">Location</span>
              <span className="textStyles">{jobData.location}</span>
            </div>
          </div>
          <hr />
          <div className="listApplyitem">
            <FontAwesomeIcon className="itemIcon" icon={faBriefcase} />
            <div className="item-info">
              <span className="label">Job Type</span>
              <span className="textStyles">{jobData.jobType.value}</span>
            </div>
          </div>
          <hr />
          <div className="listApplyitem">
            <FontAwesomeIcon className="itemIcon" icon={faBuilding} />
            <div className="item-info">
              <span className="label">Department</span>
              <span className="textStyles">{jobData.jobCategory.value}</span>
            </div>
          </div>
          <hr />
          <div className="listApplyitem">
            <FontAwesomeIcon className="itemIcon" icon={faCalendarAlt} />
            <div className="item-info">
              <span className="label">Minimum Experience</span>
              <span className="textStyles">{jobData.experienceLevel.value}</span>
            </div>
          </div>
          <hr />
          <button onClick={onClickView} id="job-btn" className="viewBtn" type="button">
            APPLY
          </button>
        </div>
      </div>
    </div>
  );
}

export default JobDetails;
