import React from 'react';
import { Link } from 'react-router-dom';
import './navbar.css';
import '../../Styles/Styles.css';

function Items(props) {
  const {
    navItems, ClassName, ItemTo, onClick, style,
  } = props;

  return (
    <div style={{ display: 'flex' }}>
      <Link className={ClassName} style={style} to={ItemTo} onClick={onClick} reloadDocument>
        {navItems}
      </Link>
    </div>

  );
}

export default Items;
