/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCogs,
  faMedal,
  faUsers,
  faCube,
} from '@fortawesome/free-solid-svg-icons';
import AboutSection from './AboutSection';

import './About.css';
import { trackScreen } from '../../configs/Analytics';

function AboutUs() {
  const whoWeArr = [
    { title: 'Innovation', icon: faCogs, summary: 'Emerging Technologies' },
    { title: 'Quality', icon: faMedal, summary: 'User-Friendly' },
    {
      title: 'Client-Centric Approach',
      icon: faUsers,
      summary: 'Satisfied clients',
    },
    { title: 'Product', icon: faCube, summary: 'Development' },
  ];
  useEffect(() => {
    trackScreen('AboutUsScreen');
    return () => {};
  }, []);

  return (
    <div className="main-container">
      <Helmet>
        <title>
          About Us - BROVITECH : Mobile App Development Company

        </title>
        <meta name="description" content="We are passionate about turning innovative ideas into robust Android applications. Our mission is to empower businesses, entrepreneurs, and individuals with cutting-edge mobile solutions that drive success. As a dedicated Android app development company, we specialize in crafting digital experiences that redefine industries and exceed expectations." />
        <link rel="canonical" href="https://brovitech.com/about" />

      </Helmet>
      <div className="about-us-container">
        <header>
          <div
            style={{ fontSize: '3.2rem', fontWeight: '600', color: '#150404' }}
          >
            About Us
          </div>
        </header>
        <div className="about-us-content">
          <div className="about-section-style">
            <div className="sectionCotainer">
              <AboutSection
                title="Who are we?"
                details="We are passionate about turning innovative ideas into robust Android applications. Our mission is to empower businesses, entrepreneurs, and individuals with cutting-edge mobile solutions that drive success. As a dedicated Android app development company, we specialize in crafting digital experiences that redefine industries and exceed expectations."
              />
            </div>
            <div className="cards-container zoom-in">
              {whoWeArr.map((item) => (
                <div className="card" key={item.index}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={item.icon}
                      style={{
                        fontSize: '30px',
                        color: 'black',
                        padding: '10px',
                      }}
                    />
                    <h2
                      style={{
                        fontSize: '16px',
                        fontWeight: '600',
                        color: 'black',
                      }}
                    >
                      {item.title}
                    </h2>
                    <h2
                      style={{
                        fontSize: '16px',
                        color: 'grey',
                        fontWeight: '500',
                      }}
                    >
                      {item.summary}
                    </h2>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mission-content">
          <div className="mission-content-inner">
            <AboutSection
              title="Our vision & mission"
              details={
                "Our mission is to provide high-quality products/services and make a positive impact on our customer's lives. Our team of experienced developers has a proven track record of success in developing high-quality mobile apps for businesses of all sizes.We envision a world where our products/services contribute to a better future for all."
              }
            />
          </div>
        </div>
        <div className="about-section-style">
          <div>
            <AboutSection
              className="titleStyle"
              title="What Brovitech does for you?"
            />

            <AboutSection details="We design, we invent, we advent, we code, we engineer, we share your load in your journey of becoming a leader. We have an advance understanding of developing websites, applications, portals, products etc that completes your enterprise." />
          </div>
        </div>
        <div className="card-container-style">
          <div className="cardContentStyle cardLeft">
            <h2
              style={{ fontWeight: '550', textAlign: 'center', color: 'black' }}
            >
              WHAT YOU NEED?
            </h2>
            <p style={{ textAlign: 'justify', padding: '1rem' }}>
              Our team of experienced professionals is here to guide you through
              the process, from ideation to implementation. We are committed to
              providing tailored solutions that drive growth, enhance your
              digital presence, and help you stay ahead in today is competitive
              market.
            </p>
          </div>
          <div className="cardContentStyle cardRight">
            <h2
              style={{ fontWeight: '550', textAlign: 'center', color: 'black' }}
            >
              WHAT BROVITECH DO?
            </h2>
            <p style={{ textAlign: 'justify', padding: '1rem' }}>
              At Brovitech Solutions, we understand that every business has
              unique needs and goals. That is why we offer a wide range of
              customizable solutions to meet your specific requirements. Explore
              our comprehensive suite of services and find out how we can help
              you achieve your business objectives.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
