import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import BrovitechLogger from '../utils/BrovitechLogger';

export const postContactPage = createAsyncThunk('postContactPage', async () => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL}/projectQuery-projectQuery`);
    BrovitechLogger.log(response);
    return response.data;
  } catch (error) {
    throw error.response.data; // Throwing the error to be caught in postContactPage.rejected
  }
});

const contactSlice = createSlice({
  name: 'contact',
  initialState: {
    isLoading: false,
    data: [],
    isSuccess: false,
    error: null,
  },
  reducers: {
    // add other reducers here if needed.
  },
  extraReducers: (builder) => {
    builder.addCase(postContactPage.pending, (state) => ({
      ...state,
      isLoading: true,
      error: null,
    }));
    builder.addCase(postContactPage.fulfilled, (state, action) => ({
      ...state,
      isLoading: false,
      data: action.payload,
      isSuccess: true,
      error: null,
    }));
    builder.addCase(postContactPage.rejected, (state, action) => ({
      ...state,
      isLoading: false,
      error: action.error, // Use action.error directly
    }));
  },
});

export default contactSlice.reducer;
