import React from 'react';
import { Helmet } from 'react-helmet';
import '../../Styles/Styles.css';
import Cards from '../../components/card/Cards';
import Products from '../portfolio/ClientsProducts';
import HeroSection from '../../components/main/HeroSection';

function Home() {
  return (
    <>
      <Helmet>
        <title>BROVITECH: React Native and Flutter Mobile App Development Company</title>
        <meta
          name="description" 
          content="Expert React Native and Flutter developers at a top mobile app development company. We are experts in both platforms and can help you create the perfect app for your business"
          
        />
        <meta name="keywords" content="Portfolio, Our Clinets, Contact Us, About Us, Jobs Openeings" />
        <link rel="canonical" href="https://brovitech.com" />

      </Helmet>
      <HeroSection />
      <Cards />
      <Products />
    </>
  );
}

export default Home;
