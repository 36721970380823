const NavItem = [
  {
    id: '1',
    navItems: 'Home',
    to: '/',
  },

  {
    id: '2',
    navItems: 'Portfolio',
    to: 'services',
  },

  {
    id: '3',
    navItems: 'Our Clients',
    to: '/products',
  },
  {
    id: '4',
    navItems: ' Contact',
    to: '/contacts',
  },
  {
    id: '5',
    navItems: ' About Us',
    to: '/about',
  },
  {
    id: '6',
    navItems: 'Job Openings',
    to: '/jobOpenings'
  }
];

export default NavItem;
