import { getAnalytics, logEvent } from 'firebase/analytics';
import BrovitechLogger from '../utils/BrovitechLogger';

const isDev = process.env.NODE_ENV === 'development';

// Initialize Firebase Analytics
let analytics;

function initAnalytics() {
    analytics = getAnalytics();
}

const Events = {
    query_submitted: 'query_submitted',
    hero_get_quote_pressed: 'hero_get_quote_pressed',
    footer_get_quote_pressed: 'footer_get_quote_pressed',
    header_menu_pressed: 'header_menu_pressed', // menu_name
    quick_links_pressed: 'quick_links_pressed', // link_name,
    query_form_submission: 'query_form_submission',
    query_form_submission_error: 'query_form_submission_error',
    job_form_submitted: 'job_form_submitted',
    apply_to_job_pressed: 'apply_to_job_pressed',
    contact_us_pressed: 'contact_us_pressed'
};

function trackEvent(eventName, params = {}) {
    if (isDev || !analytics) {
        BrovitechLogger.log('trackEvent_', eventName, params);
      } else {
        logEvent(analytics, eventName, params);
      } 
}

function trackScreen(screenName) {
    trackEvent('screen_view', screenName);
}

export { trackEvent, trackScreen, Events, initAnalytics };
