import React from 'react';
import './JobOpenings.css';

function InputFieldComponent({
  label,
  name,
  type,
  value,
  onChange,
  required,
  error,
  children,
  onClick,
  accept, ref, inputmode, suffix }) {
  return (
    <div style={{ padding: '10px 20px' }}>
      <label htmlFor={name} className="form_label">{`${label} (${required ? 'required' : 'optional'})`}</label>
      {children || (
      <div className="input-with-suffix">
        <input
          className="form_fields no-spinner"
          type={type}
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          required={required}
          placeholder={label}
          accept={accept}
          onClick={onClick}
          ref={ref}
          inputMode={inputmode}
        />
        {suffix && <span className="suffix">{suffix}</span>}
      </div>

      )}
      {error && (
        <div className="error-field">{error}</div>
      )}
    </div>
  );
}

export default InputFieldComponent;
