/* eslint-disable no-console */
const isDevMode = process.env.NODE_ENV === 'development';

const BrovitechLogger = {
  log: (...args) => {
    if (isDevMode) {
      console.log(...args);
    }
  },
  error: (...args) => {
    if (isDevMode) {
      console.error(...args);
    }
  },
  warn: (...args) => {
    if (isDevMode) {
      console.warn(...args);
    }
  },
  info: (...args) => {
    if (isDevMode) {
      console.info(...args);
    }
  },
  debug: (...args) => {
    if (isDevMode) {
      console.debug(...args);
    }
  },
};

export default BrovitechLogger;
