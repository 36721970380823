import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { initializeApp } from 'firebase/app';
import MainRoute from './routes';
import './Styles/Styles.css';
// import firebaseConfig from './configs/firebaseconfig';
import { initAnalytics } from './configs/Analytics';
// import { initAppCheck } from './configs/appcheck';

// initAppCheck(initializeApp(firebaseConfig));
initAnalytics();
function App() {
  return (
    <>
      <MainRoute />
      <ToastContainer />
    </>
  );
}

export default App;
