import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../card/Cards.css';
import { ShimmerSimpleGallery } from 'react-shimmer-effects';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { fetchServices } from '../../store/servicesSlice';

function Service() {
  const dispatch = useDispatch();
  const { data, isLoading, isSuccess } = useSelector(
    (state) => state.serviceData
  );
  const [selectedService, setSelectedService] = useState(null);
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    if (data.length === 0) {
      dispatch(fetchServices());
    }
  }, []);

  if (isLoading === true) {
    return <ShimmerSimpleGallery card imageHeight={300} caption row={1} />;
  }

  const scaleWhileInView = {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1,
      ease: 'easeInOut',
      stiffness: 50,
    },
  };

  const handleSelectedItem = (item) => {
    setSelectedService(item);
    setIsInView(true);
  };

  return (
    <div className="service-container">
      <motion.div className="serviceList">
        {isSuccess && (
          <>
            {data.services.map((item, index) => (
              <motion.div
                whileInView={() => {
                  setIsInView(true);
                  return {};
                }}
                key={item.id}
                className="cardStyle"
                initial={{
                  x: `${50 * (index + 1 * 2)}px`,
                  opacity: 0,
                  scale: 0.95,
                }}
                animate={isInView && {
                  ...scaleWhileInView,
                }}
                transition={{ duration: 0.25 }}
                whileHover={{ scale: 1 }}
                onClick={() => handleSelectedItem(item)}
              >
                <img
                  className="service_img"
                  alt="zxcmh"
                  src={item.iconUrl}
                  style={{
                      width: '54px',
                      height: '54px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      justifyItems: 'left',
                    }}
                />
                <h4
                  className="my-3"
                  style={{
                      textAlign: 'left',
                      fontSize: '18px',
                      fontWeight: 600,
                    }}
                >
                  {item.title}
                </h4>
                <motion.div
                  initial={{
                      x: `${50 * (index + 1 * 2)}px`,
                      opacity: 0,
                    }}
                  animate={isInView && {
                      ...scaleWhileInView,
                    }}
                  className="text-muted"
                  style={{
                      textAlign: 'justify',
                      fontSize: '15px',
                      wordSpacing: ' -1px',
                    }}
                >
                  {item.description}
                </motion.div>
              </motion.div>
            ))}
          </>
        )}
      </motion.div>
      {selectedService && (
      <Helmet>
        <title>{selectedService.title}</title>
        <meta name="description" content={selectedService.description} />
      </Helmet>
      )}
    </div>
  );
}

export default Service;
