import React from 'react';
import '../Styles/Styles.css';
// import spinner from '../data/Assets/spinner.gif';

function Spinner() {
  const newtext = 'please wait';
  return (
    <div className="loadingSpinnerContainer">
      <div className="loadingSpinner" />
      
      <div className="loader-message">{newtext}</div>
    </div>
    // <div className="loadingSpinnerContainer">
    //   <img src={spinner} alt="loading" width={100} />
    
    // </div>
  );
}

export default Spinner;
