import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchAchievements = createAsyncThunk('fetchAchievements', async () => {
        const response = await axios.get(`${process.env.REACT_APP_URL}/achievements-getAchievements`);
        return response.data;
});

const clientUserSlice = createSlice({
    name: 'achievements',
    initialState: {
        isLoading: false,
        data: [],
        isSuccess: false,
        error: null,
    },
    reducers: {
        // add other reducers here if needed.
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAchievements.pending, (state) => ({
            ...state,
            isLoading: true,
            error: null,
          }));
        builder.addCase(fetchAchievements.fulfilled, (state, action) => ({
            ...state,
            isLoading: false,
            data: action.payload,
            isSuccess: true,
            error: null,
          }));
        builder.addCase(fetchAchievements.rejected, (state, action) => ({
            ...state,
            isLoading: false,
            error: action.error.message,
          }));
    }

});

export default clientUserSlice.reducer;
