/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ShimmerSectionHeader, ShimmerThumbnail } from 'react-shimmer-effects';
import { useLocation } from 'react-router-dom';
import '../../Styles/Styles.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { Helmet } from 'react-helmet';
import { fetchPortfolios } from '../../store/protfoliosSlice';
import { trackScreen } from '../../configs/Analytics';

const indicatorStyles = {
  background: '#d9d9d9',
  width: 6,
  height: 6,
  display: 'inline-block',
  margin: '0 5px',
  borderRadius: '8px',
};

function Portfolios() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { data, isLoading, isSuccess } = useSelector(
    (state) => state.portfolios
  );
  useEffect(() => {
    const title = location.pathname === '/services' ? 'Portfolio - BROVITECH : Mobile App Development Company' : 'BROVITECH : Mobile App Development Company';
    document.title = title;
  }, [location.pathname]);

  useEffect(() => {
    trackScreen('PortfoliosScreen');
    return () => { };
  }, []);

  useEffect(() => {
    if (data.length === 0) {
      dispatch(fetchPortfolios());
    }
  }, []);

  if (isLoading === true) {
    return (
      <>
        <ShimmerSectionHeader center />
        <ShimmerThumbnail height={600} width={300} rounded center />
      </>
    );
  }

  return (
    <div className="cards_serv">
      <Helmet>
        <title>{location.pathname === '/services' ? 'Portfolio - BROVITECH : Mobile App Development Company' : 'BROVITECH : Mobile App Development Company'}</title>
        <meta name="description" content="Projects that speak about our versatility" />
      </Helmet>
      <h2 style={{ color: 'black' }}> Our work</h2>
      <h4 style={{ color: 'black' }}>
        Projects that speak about our versatility
      </h4>
      {isSuccess && (
        <Carousel
          className="multiCaro"
          autoPlay
          showIndicators
          infiniteLoop
          showArrows
          showThumbs={false}
          showStatus={false}
          renderArrowNext={(onClickHandler) => (
            <button
              type="button"
              className="arrow-button"
              onClick={onClickHandler}
              style={{
                position: 'absolute',
                zIndex: 2,
                top: '40%',
                right: '162px',
                transform: 'translateY(-50%)',
                background: 'rgba(0,0,0,0.0)',
                border: 'none',
                color: 'black',
                borderRadius: '50%',
                padding: '6px',
                fontSize: '36px',
                cursor: 'pointer',
                fontWeight: 'bold',
              }}
            >
              &gt;
            </button>
          )}
          renderArrowPrev={(onClickHandler) => (
            <button
              type="button"
              className="arrow-button"
              onClick={onClickHandler}
              style={{
                position: 'absolute',
                background: 'rgba(0,0,0,0.0)',
                zIndex: 2,
                top: '40%',
                left: '136px',
                transform: 'translateY(-50%)',
                border: 'none',
                color: 'black',
                borderRadius: '50%',
                padding: '6px',
                cursor: 'pointer',
                fontSize: '36px',
                fontWeight: 'bold',
              }}
            >
              &lt;
            </button>
          )}
          renderIndicator={(onClickHandler, isSelected) => {
            if (isSelected) {
              return <li style={{ ...indicatorStyles, background: '#000' }} />;
            }
            return <li style={indicatorStyles} onClick={onClickHandler} />;
          }}
        >
          {data.portfolio.map((item) => (
            <div key={item.id} className="portfolio-item">
              <div className="portfolio-item-content">
                <div className="image-container">
                  <img alt="Df" src={item.imageUrl} className="caro_img" />
                </div>
                <p
                  style={{
                    color: 'black',
                    padding: 0,
                    fontSize: '2rem',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  {item.name}
                </p>
                <div className="app-store-icons">
                  {item.appStore && (
                    <a
                      href={item.appStore}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src="images/ios.png" alt="App Store" />
                    </a>
                  )}
                  {item.playStore && (
                    <a
                      href={item.playStore}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src="images/android.png" alt="Play Store" />
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      )}
    </div>
  );
}

export default Portfolios;
