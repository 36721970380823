import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchPortfolios = createAsyncThunk('fetchPortfolios', async () => {
  const response = await axios.get(`${process.env.REACT_APP_URL}/portfolio-getPortfolio`);
  return response.data;
});

const portfoliosSlice = createSlice({
    name: 'portfolios',
    initialState: {
      isLoading: false,
      data: [],
      isSuccess: false,
      error: null,
    },
    reducers: {
      // add other reducers here if needed.
    },
    extraReducers: (builder) => {
      builder.addCase(fetchPortfolios.pending, (state) => ({
        ...state,
        isLoading: true,
        error: null,
      }));
      builder.addCase(fetchPortfolios.fulfilled, (state, action) => ({
        ...state,
        isLoading: false,
        data: action.payload,
        isSuccess: true,
        error: null,
      }));
      builder.addCase(fetchPortfolios.rejected, (state, action) => ({
        ...state,
        isLoading: false,
        error: action.error.message,
      }));
    },
  });
  
export default portfoliosSlice.reducer;
