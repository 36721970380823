import './navbar.css';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import NavItem from '../../data/NavBarMenus';
import Items from './navItems';
import '../../Styles/Styles.css';
import { Events, trackEvent } from '../../configs/Analytics';

function Navbar() {
  const [click, setClick] = useState(false);
  const [navbar] = useState(false);
  const handleClick = () => {
    setClick(!click);
  };

  const closeMobileMenu = () => {
    setClick(false);
  };

  // const changeBackground = () => {
  //   if (window.scrollY >= 80 && !navbar) {
  //     setNavbar(true);
  //   } else if (window.scrollY < 80 && navbar) {
  //     setNavbar(false);
  //   }
  // };
  
  // useEffect(() => {
  //   window.addEventListener('scroll', changeBackground);
  //   return () => {
  //     window.removeEventListener('scroll', changeBackground);
  //   };
  // }, [navbar]); 

  // /images/B-2.jpg
  return (
    <nav className={navbar ? 'navbar active' : 'navbar '}>
      <div className="navbar-container">
        <div>
          <Link to="/" onClick={closeMobileMenu}>
            {/* <img
              src="images/broviLogo.png"
              alt="logo"
              className="navbar-logo"
            /> */}
            <img
              src={`${process.env.PUBLIC_URL}/images/broviLogo.png`}
              alt="logo"
              className="navbar-logo"
            />

          </Link>
        </div>
        <div
          className="menu-icon"
          onClick={handleClick}
          onKeyDown={(e) => {
    if (e.key === 'Enter') {
      handleClick();
    }
  }}
          role="button"
          tabIndex={0}
        >
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>

        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          {NavItem.map((item) => (
            <li key={item.id} className="nav-item">
              <Items
                ClassName="nav-links"
                ItemTo={item.to}
                onClick={() => {
                  trackEvent(Events.header_menu_pressed, { menu: item.navItems });
                  closeMobileMenu();
                }}
                navItems={item.navItems}
              />
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
