import React from 'react';
// import '../../Styles/Styles.css';
import './Cards.css';
import Service from '../services/Services';
import Label from '../Label';
import Paragrah from '../main/Paragraph';
import { Achievements } from '../services/Achievements';
import Services from '../../pages/portfolio/Portfolio';

function Cards() {
  return (
    <div className="page-section-services" id="services">

      <div className="container">

        <div className="text-center">
          <Achievements />

          <Label ClassName="serviceClassName" label="Our services" textClassName="section-heading-card" />

          <Paragrah
            p="We engage in all stages of product development, right from design to implementation and support. We deliver quality software using multiple models and are flexible to implement other models as per the requirements of the client."
            textClassName="section-subheading text-muted text-center"
          />
          <Service />
        </div>
      </div>
      <div style={{
  background: 'linear-gradient(to bottom, #adb5bd, #ffffff)'
}}
      >
        <div className="container">
          <Services />
        </div>
      </div>
    </div>
  );
}

export default Cards;
