import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchJobOpeningsList = createAsyncThunk('fetchJobOpeningsList', async () => {
  const response = await axios.get(`${process.env.REACT_APP_URL}/jobsOpenings-getJobsPosts`);
  return response.data;
});

const jobOpeningsSlice = createSlice({
    name: 'jobsOpenings',
    initialState: {
      isLoading: false,
      data: [],
      isSuccess: false,
      error: null,
    },
    reducers: {
      // add other reducers here if needed.
    },
    extraReducers: (builder) => {
      builder.addCase(fetchJobOpeningsList.pending, (state) => ({
        ...state,
        isLoading: true,
        error: null,
      }));
      builder.addCase(fetchJobOpeningsList.fulfilled, (state, action) => ({
          ...state,
          isLoading: false,
          data: action.payload,
          isSuccess: true,
          error: null,
        }));
      builder.addCase(fetchJobOpeningsList.rejected, (state, action) => ({
        ...state,
        isLoading: false,
        error: action.error.message,
      }));
    },
    
  });
  
export default jobOpeningsSlice.reducer;
