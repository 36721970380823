import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchServices = createAsyncThunk('fetchServices', async () => {
        const response = await axios.get(`${process.env.REACT_APP_URL}/services-getServices`);
        return response.data;
});

const servicesSlice = createSlice({
    name: 'serviceData',
    initialState: {
      isLoading: false,
      data: [],
      isSuccess: false,
      error: null,
    },
    reducers: {
      // add other reducers here if needed.
    },
    extraReducers: (builder) => {
      builder.addCase(fetchServices.pending, (state) => ({
        ...state,
        isLoading: true,
        error: null,
      }));
      builder.addCase(fetchServices.fulfilled, (state, action) => ({
        ...state,
        isLoading: false,
        data: action.payload,
        isSuccess: true,
        error: null,
      }));
      builder.addCase(fetchServices.rejected, (state, action) => ({
        ...state,
        isLoading: false,
        error: action.error.message,
      }));
    },
  });
  
export default servicesSlice.reducer;
