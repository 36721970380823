import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import { Card } from 'react-bootstrap';
import '../../Styles/Styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation } from 'react-router-dom';
import {
  FreeMode,
  Navigation,
  Pagination,
  Autoplay,
} from 'swiper';

import {
  ShimmerTable,
  ShimmerSectionHeader,
} from 'react-shimmer-effects';

import Paragraph from '../../components/main/Paragraph';
import Button from '../../components/button/Button';

import Technologies from '../technologies/index';
import Testimonial from './ClientTestimonial';
import { fetchClientUsers } from '../../store/clientUserSlice';

function Products() {
  const dispatch = useDispatch();
  const { data, isLoading, isSuccess } = useSelector((state) => state.clientUsers);
  const location = useLocation();

  useEffect(() => {
    const title = location.pathname === '/products' ? 'Client - BROVITECH : Mobile App Development Company'
    : 'BROVITECH : Mobile App Development Company';
    document.title = title;
  }, [location.pathname]);

  useEffect(() => {
    if (data.length === 0) {
      dispatch(fetchClientUsers());
    }
  }, []);

  if (isLoading === true) {
    return (
      <>
        <ShimmerSectionHeader center line={1} />
        <ShimmerTable row={0} col={5} />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{location.pathname === '/products' ? 'Clients - BROVITECH : Mobile App Development Company' : 'BROVITECH : Mobile App Development Company'}</title>
        <meta name="description" content="Explore clients in Brovitech Website" />
      </Helmet>
      <div className="container pycards_client-4 px-4 justify-content">
        <div className="cards_client">
          <h1 className="client_h1">CLIENTS</h1>
          <h4>We Build apps for</h4>

          <Swiper
            freeMode
            grabCursor
            modules={[FreeMode, Navigation, Pagination, Autoplay]}
            className="mySwiper"
            slidesPerView={5}
            spaceBetween={25}
            navigation
            autoplay={{ delay: 1000 }}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              480: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 15,
              },
              1280: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
            }}
          >
            <Card className="p-0 overflow-hidden h-100 shadow">
              <div className="productSlide overflow-hidden rounded p-0 bg-light">
                <div className="swiper-pagination">
                  <div className="swiper-button-prev">
                    <img alt="Previous" src="img/arrow_left.png" className="previous-image" />
                  </div>
                  <div className="swiper-button-next">
                    <img alt="Next" src="img/arrow_right.png" />
                  </div>
                </div>
                {isSuccess && (
                  <div>
                    {data.clients?.map((item) => (
                      <SwiperSlide key={item.id} className="our_clients">
                        <img alt="Client" src={item.imageUrl} />
                      </SwiperSlide>
                    ))}
                  </div>
                )}
              </div>
            </Card>
          </Swiper>
        </div>
      </div>
      <Technologies />
      <Testimonial />
      <div className="container_project">
        <Paragraph textClassName="nextProject" p="Let's talk about your next project" />
        <div className="pr-btns">
          <Button>GET A QUOTE</Button>
        </div>
      </div>
    </>
  );
}

export default Products;
