import React from 'react';
import './About.css';

function AboutSection(props) {
  const { title, details } = props;
  return (
    <div className="about_us_section">
      <h2 className="about_title">{title}</h2>
      <p className="about_details">{details}</p>
    </div>
  );
}

export default AboutSection;
