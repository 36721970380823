import React from 'react';

function FormattedJobDescription({ jobDescription, className }) {
  const lines = jobDescription.split('\n').filter((line) => line.trim() !== '');

  return (
    <div className={className} style={{ marginBottom: '8px' }}>
      <ul style={{ listStyleType: 'disc', paddingLeft: '20px', margin: '0' }}>
        {lines.map((line) => (
          <li key={line} style={{ listStyleType: 'disc', marginBottom: '4px' }}>
            {line}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FormattedJobDescription;

// import React from 'react';

// function FormattedJobDescription({ jobDescription, className }) {
//   const lines = jobDescription.split('\n').filter((line) => line.trim() !== '');

//   return (
//     <div className={className} style={{ marginBottom: '8px' }}>
//       {lines.map((line, index) => (
//         <div key={line} style={{ marginBottom: '4px' }}>
//           {lines.length > 1 && `${index + 1}. `}
//           {line}
//           {line}
//         </div>
//       ))}
//     </div>
//   );
// }

// export default FormattedJobDescription;
