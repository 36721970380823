/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable react/jsx-pascal-case */
import React, { useState, useRef, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './JobOpenings.css';
// import { toast } from 'react-toastify';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { collection, getDocs } from 'firebase/firestore'; // Import Firestore functions
import { storage, firestore } from '../../configs/firebaseconfig';
import Success_post_dialog from '../../components/Success_post_dialog';
import Spinner from '../../components/Spinner';
import InputField from './InputFieldComponent';
import TextareaFieldComponent from './TextareaFieldComponent';
import BrovitechLogger from '../../utils/BrovitechLogger';
import { Events, trackEvent, trackScreen } from '../../configs/Analytics';

function JobOpeningForm() {
  const scrollRef = useRef(null);
  const [fileInputKey, setFileInputKey] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState(false);
  // const [textAreaValue, setTextAreaValue] = useState('');
  // const [urls, setUrls] = useState('');
  const { jobsDocId } = useParams();
  const location = useLocation();
  const { jobData } = location.state;
  BrovitechLogger.log('jobformdata', jobData.jobTitle.value);
  // BrovitechLogger.log('jobsId', jobsDocId);
  const [capta, setCapta] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [verified, setVerified] = useState(false);
  const [selctionType, setselctionType] = useState([]);

  const [formData, setFormData] = useState({
    Name: '',
    phoneNumber: '',
    Email: '',
    // jobTitle: jobData.jobTitle,
    resume: null,
    address: '',
    educationLevel: '',
    passoutYear: '',
    experience: '',
    howDidYouHearAboutUs: '',
    urlResume: null,
    currentCompanyName: '',
    currentSalary: '',
    expectedSalary: '',
    noticePeriod: ''

  });

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      trackScreen('JobOpeningForm');
    }
  }, []);

  const captchaRef = useRef(null);
  const [errors, setErrors] = useState({});

  const onChange = (value) => {
    setCapta(true);
    setVerified(true);
    BrovitechLogger.log('Captcha value:', value);

    BrovitechLogger.log('capta', capta);
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };
  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // Clear specific errors based on the field being modified
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
      invalidEmail: name === 'Email' ? undefined : prevErrors.invalidEmail,
      emailError: name === 'Email' ? undefined : prevErrors.emailError,
      invalidNumber: name === 'phoneNumber' ? undefined : prevErrors.invalidNumber,
      phoneNumber: name === 'phoneNumber' ? undefined : prevErrors.phoneNumber,
    }));
  
    // Validate for text-only input in the "Name" field
    if (name === 'Name' && !/^[A-Za-z\s]+$/.test(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '*Only text is allowed in the Name field',
      }));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  
  useEffect(() => {
    const fetchselctionType = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'jobPostSelection'));
        const titles = querySnapshot.docs.map((doc) => doc.data());
        setselctionType(titles);
      } catch (error) {
        BrovitechLogger.error('Error fetching job titles:', error);
      }
    };

    fetchselctionType();
  }, []);
 
  const handleFileChange = (e) => {
    // const { name, files } = e.target;
    const file = e.target.files[0];
    setErrors((prevErrors) => ({
      ...prevErrors,
      resume: undefined,
    }));
    if (file) {
      setFormData({
        ...formData,
        resume: file
      });
    }
  };
  // Increment the key to reset the input field
  // This is temporary solution to reset file
  const resetFileInput = () => {
    setFileInputKey((prevKey) => prevKey + 1);
  };

  function isValidMobileNumber(num) {
    const pattern = /^\d{10}$/;
    return pattern.test(num);
  }

  function isValidEmail(email) {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }

  // validateForm
  const validForm = () => {
    const newError = {};
    if (!formData.Name) {
      newError.Name = '*Name is required';
    }
    if (!formData.Email) {
      newError.email = '*Email isRequired';
    }
    if (!formData.phoneNumber) {
      newError.phoneNumber = '*Enter Valid Number';
    }
    if (!formData.address) {
      newError.address = '*Address is required';
    }
    if (!formData.educationLevel) {
      newError.educationLevel = '*Please select education level';
    }
    if (!formData.passoutYear) {
      newError.passoutYear = '*Please select passout year';
    }
    if (!formData.experience) {
      newError.experience = '*Please select experience';
    }
    // if (!formData.salaryExpectation) {
    //   newError.salaryExpectation = '*Please select salaryExpectation';
    // }
    if (!formData.resume) {
      newError.resume = '*Resume is required';
    }
    if (!formData.howDidYouHearAboutUs) {
      newError.howDidYouHearAboutUs = '*Please provide reference';
    }
    // if (!formData.jobTitle) {
    //   newError.jobTitle = '*JobTitle is Required';
    // }
    if (isValidMobileNumber(formData.phoneNumber) === false) {
      newError.invalidNumber = '*Enter Ten digit Mobile Number';
    }
    if (isValidEmail(formData.Email) === false) {
      newError.invalidEmail = '*Please provide valid Email';
    }
    if (!capta) {
      newError.capta = '*Required';
    }

    setErrors(newError);
    return Object.keys(newError).length === 0;
  };

  // const currentSalaryWithSuffix = `${formData.currentSalary} LPA`;

  const currentSalaryWithSuffix = formData.currentSalary === '' ? '' : `${formData.currentSalary} LPA`;

  const expectedSalarySuffix = formData.expectedSalary === '' ? '' : `${formData.expectedSalary} LPA`;
  const handleSubmit = async (e) => {
    const token = captchaRef.current.getValue();
    captchaRef.current.reset();
    BrovitechLogger.log(token);
    e.preventDefault();
    const isFormValid = validForm();

    if (isFormValid) {
      setVerified(false);
      const currentDate = new Date();
      const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
      const formattedTime = `${currentDate.getHours().toString().padStart(2, '0')}-${currentDate.getMinutes().toString().padStart(2, '0')}-${currentDate.getSeconds().toString().padStart(2, '0')}`;
      const fileName = `${formData.Email}_${formattedDate}_${formattedTime}.pdf`;

      // Upload the resume to Firebase Storage 
      const storageRef = ref(storage, `/files/${fileName}`);
      const uploadTask = uploadBytesResumable(storageRef, formData.resume);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          // Update progress
          BrovitechLogger.log(`Upload progress: ${percent}%`);
        },
        (err) => {
          BrovitechLogger.error('Error uploading resume:', err);
          alert('Error uploading resume. Please try again later.');
        },
        () => {
          setLoading(true);
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            // download url
            setFormData({
              ...formData,
              urlResume: url,
            });
            // resetFileInput();

            const postData = {
              name: formData.Name,
              email: formData.Email,
              aboutUs: formData.howDidYouHearAboutUs,
              educationLevel: formData.educationLevel,
              passoutYear: formData.passoutYear,
              experience: formData.experience,
              jobTitle: jobData.jobTitle.value,
              phoneNo: formData.phoneNumber,
              resume: url,
              address: formData.address,
              jobsDocId,
              currentCompanyName: formData.currentCompanyName,
              currentSalary: currentSalaryWithSuffix,
              expectedSalary: expectedSalarySuffix,
              noticePeriod: formData.noticePeriod,

            };
            BrovitechLogger.log('postData', postData);
            // setLoading(true);
            axios
              .post(
                'https://us-central1-updatedwebsite-d336b.cloudfunctions.net/jobsOpenings-fetchApplicantsDetails',
                postData
              )
              .then((response) => {
                BrovitechLogger.log('Call Successful:', response);
                setLoading(false);
                trackEvent(Events.job_form_submitted, {
                  name: formData.Name,
                  email: formData.Email,
                  jobTitle: formData.jobTitle,
                });

                setFormData((prevState) => ({
                  ...prevState,
                  // salaryExpectation: '',
                  Name: '',
                  phoneNumber: '',
                  Email: '',
                  jobTitle: '',
                  resume: null,
                  address: '',
                  educationLevel: '',
                  passoutYear: '',
                  experience: '',
                  howDidYouHearAboutUs: '',
                  urlResume: null,
                  currentCompanyName: '',
                  currentSalary: '',
                  expectedSalary: '',
                  noticePeriod: ''
                }));
                resetFileInput();
                setDialog(true);
              })
              .catch((error) => {
                if (error.response && error.response.status === 400 && error.response.data && error.response.data.message === 'Email already exists.') {
                  // Email already exists error
                  setEmailError('Email Id already exists'); 
                  alert('Email Id already exists');
                } else {
                  BrovitechLogger.error('Call error:', error);
                  setLoading(false);
                  alert('Error submitting form. Please try again later.');
                  trackEvent(Events.form_submission_error, {
                    error_message: error.message,
                  });
                } 
                setLoading(false);
              });
          });
        }
      );
    }
  };

  return (

    <div ref={scrollRef} className="job-form-card">
      <Helmet>
        <title>
          Job Application Form - BROVITECH : Mobile App Development Company
        </title>
        <meta name="description" content={`Apply for the job opening for ${jobData.jobTitle.value} at ${jobData.companyName} and join our dynamic team. Fill out the application form with your details and submit your resume.`} />
      </Helmet>
      <div className="job-head">
        <h1 style={{ fontSize: '3rem', fontWeight: 'bold' }}>Job Application Form</h1>
      </div>

      <form>
        {dialog === true && <Success_post_dialog />}
        {loading && <Spinner />}

        {/* once the container width issue is fixed remove this adjacent div */}

        <div className="grid">
          <InputField label="Name" name="Name" type="text" value={formData.Name} onChange={handleChange} required error={errors.Name} accept={null} />

          {/* <InputField label="Email" name="Email" type="text" value={formData.Email} onChange={handleChange} required error={errors.invalidEmail} accept={null} /> */}
          <InputField
            label="Email"
            name="Email"
            type="text"
            value={formData.Email}
            onChange={handleChange}
            required
            error={errors.invalidEmail || emailError}
            accept={null}
          />

          <InputField label="Phone Number" name="phoneNumber" type="number" inputmode="numeric" value={formData.phoneNumber} onChange={handleChange} required error={errors.invalidNumber ? errors.invalidNumber : errors.phoneNumber} accept={null} />

          <InputField label="Job Title" name="jobTitle" type="text" required value={jobData.jobTitle.value} />

          <InputField
            label="Resume"
            name="resume"
            type="file"
            onChange={handleFileChange}
            required
            error={errors.resume}
            accept=".pdf,.doc,.docx"
            // ref={aRef} 
            key={fileInputKey}
          />

          <InputField
            label="Education Level"
            name="educationLevel"
            type="text"
            required
            onChange={handleChange}
            value={formData.educationLevel}
            error={errors.educationLevel}
          >
            <select
              className="select_form"
              id="educationLevel"
              name="educationLevel"
              value={formData.educationLevel}
              onChange={handleChange}
              required
            >
              <option value="">Select Education Level</option>
              {selctionType.length > 0 && selctionType[0].educationLevel.map((title, index) => (
                <option key={index} value={title}>
                  {title}
                </option>
              ))}
            </select>
          </InputField>
       
          <InputField
            label="Passout Year"
            name="passoutYear"
            // required
            onChange={handleChange}
            value={formData.passoutYear}
            error={errors.passoutYear}

          >
            <select className="select_form" id="passoutYear" name="passoutYear" value={formData.passoutYear} onChange={handleChange}>
              <option value="">Select Passout Year</option>
              {selctionType.length > 0 && selctionType[0].passoutYear.map((title, index) => (
                <option key={index} value={title}>
                  {title}
                </option>
              ))}
            </select>
          </InputField>

          <InputField
            label="Experience"
            name="experience"
            onChange={handleChange}
            value={formData.experience}
            error={errors.experience}
            required
          >
            <select className="select_form" id="experience" name="experience" onChange={handleChange} value={formData.experience} required>
              <option value="">Select Experience</option>
              {selctionType.length > 0 && selctionType[0].experience.map((title, index) => (
                <option key={index} value={title}>
                  {title}
                </option>
              ))}
            </select>
          </InputField>
          {formData.experience !== 'Fresher' && formData.experience !== '' && (
          <>      
            <InputField
              label="Current Company Name"
              name="currentCompanyName"
              onChange={handleChange}
              value={formData.currentCompanyName}
              accept={null}
              required
            />
            <InputField
              label="Current Salary (Annual)"
              name="currentSalary"
              value={formData.currentSalary}
              onChange={handleChange}
              accept={null}
              type="number"
              suffix="LPA"
              required
            />
            <div>
              <InputField
                label="Expected Salary"
                name="expectedSalary"
                type="number"
                value={formData.expectedSalary}
                onChange={handleChange}
                suffix="LPA"
                accept={null}
                required
              />
            </div>
        
            <InputField
              label="Notice Period"
              name="noticePeriod"
              value={formData.noticePeriod} 
              required
              onChange={handleChange}
              accept={null}
            >
              <select className="select_form" id="noticePeriod" name="noticePeriod" onChange={handleChange} value={formData.noticePeriod} required>
                <option value="">Select Notice Period</option>
                {selctionType.length > 0 && selctionType[0].noticePeriod.map((title, index) => (
                  <option key={index} value={title}>
                    {title}
                  </option>
              ))}
              </select>
            </InputField>
          </>
          )}
         
          <TextareaFieldComponent label="Address" name="address" type="text" value={formData.address} onChange={handleChange} required error={errors.address} accept={null} />

          <TextareaFieldComponent label="Reference" name="howDidYouHearAboutUs" type="text" value={formData.howDidYouHearAboutUs} onChange={handleChange} required error={errors.howDidYouHearAboutUs} />

        </div>
        {/* </div> */}

        <div className="capta-field form-list">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_SITE_KEY}
            ref={captchaRef}
            onChange={onChange}

          />

          {errors.capta && (
            <div className="error-capta">{errors.capta}</div>
          )}
        </div>
        <div className="center-wrapper">

          <button
            disabled={!verified}
            onClick={handleSubmit}
            id="job-btn"
            className="form-btn"
            type="button"
          // style={{ margin: '1.5rem' }}
          >
            APPLY
          </button>

        </div>

      </form>

    </div>

  );
}

export default JobOpeningForm;
